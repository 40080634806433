@charset "utf-8";

// This is where the NoRent.org static assets will be, relative to
// where this CSS file will be when the site is deployed.
$norent-root: "../norent";

$jf-body-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
$jf-title-family: "Montserrat Black", sans-serif;
$jf-alt-title-family: "Montserrat Bold", sans-serif;

@import "../_util.scss";
@import "./_bulma-overrides.scss";
@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/bulma-divider/dist/css/bulma-divider.sass";
@import "../_supertiny.scss";
@import "../_a11y.scss";
@import "../_safe-mode.scss";
@import "../_modal.scss";
@import "./_modal-overrides.scss";
@import "../_loading-overlay.scss";
@import "../_progress.scss";
@import "../_dev.scss";
@import "../_forms.scss";
@import "./forms-overrides.scss";
@import "../_buttons.scss";
@import "./_button-overrides.scss";
@import "../_big-list.scss";
@import "../_animations.scss";
@import "../_footer.scss";
@import "./_footer-overrides.scss";
@import "../_letter-preview.scss";
@import "../_autocomplete.scss";
@import "../_helpers.scss";
@import "../_language-toggle.scss";
@import "../_email.scss";
@import "./_email-overrides.scss";
@import "../_accordion.scss";

@import "./_fonts.scss";

// These variables define the background and content colors for the nav-bar
$jf-navbar-background: $white;
$jf-navbar-content: $black;

@import "../_navbar.scss";
@import "./_navbar-overrides.scss";

@import "../_demo-deployment-note.scss";

@import "./_misc.scss";

@import "./_primary_pages.scss";
@import "./_letter-builder.scss";
